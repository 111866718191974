<!--
 * @Description: 
 * @Author: Chp
 * @Date: 2021-04-07 10:28:27
 * @LastEditTime: 2021-06-01 17:09:51
 * @LastEditors: Chp
 * @Reference: 
-->
<template>
  <div class="other-info-article">
    <ArticleTitle :info="detail"></ArticleTitle>
    <RichArticle :html="detail.contentDetails"></RichArticle>
    <div class="file">
      <strong>相关附件：</strong>
      {{ detail.fileName }}
      <!-- <a class="download" download :href="download()">下载</a> -->
      <form :action="download()" method="post">
        <button class="download" type="submit">下载</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      detail: '',//详情
      id: ''
    }
  },
  created () {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  methods: {
    /**
   * @description: 获取详情
   * @param  {id:this.id}
   * @return {*}
   */
    getDetail () {
      let params = {
        id: this.id
      }
      this.$api.getDetail(params).then((res) => {
        this.detail = res.data.data.info;
        if (this.detail.contentUrl) {
          //截取文件名
          this.detail.fileName = this.detail.contentUrl.split('/').pop()
        }
      })
    },
    /**
     * @description: 下载附件
     */
    download () {
      const { fileName, id } = this.detail
      let url = `${process.env.VUE_APP_BASE_API}/cms/oss/download/${id}/${fileName}.do`
      return url
    }
  },
}
</script>

<style scoped lang='scss'>
.other-info-article {
  .file {
    padding-top: 24px;
    border-top: 1px solid #ebedf0;
    form {
      display: inline-block;
    }
    .download {
      padding-left: 25px;
      margin-left: 50px;
      cursor: default;
      background: url(~@/assets/images/icon/icon_xiazai_a.png) no-repeat center
        left;
      background-size: 18px 18px;
      color: #e25554;
      font-size: 14px;
      text-decoration: none;
      display: inline-block;
      height: 18px;
      line-height: 18px;
      border: none;
      &:hover {
        color: $main-color;
        background: url(~@/assets/images/icon/icon_xiazai_b.png) no-repeat
          center left;
        background-size: 18px 18px;
      }
    }
  }
}
</style>